@import '../../../../styles/variables.less';

.timeline-button {
  position: fixed !important;
  z-index: 50;
  bottom: 0;
  right: 0;
  border-radius: 8px 0px 0px 0px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background: @white-color;
  color: @primary-color;
  width: 376px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

#hub-list-component {
  width: 98%;
  margin: auto;
  .ant-table-content {
    th.ant-table-cell {
      color: @text-dark;
      font-size: 14.5px;
      font-weight: 500;
    }
  }

  .search-hub {
    .custom-search-input {
      border-bottom: none;
    }

    .ant-input-suffix {
      svg {
        width: 25px;
        height: 25px;
      }
    }

    .ant-input {
      border: 1px solid;
      background: #ffffff;
      border-color: #d1d1d1;
      border-radius: 4px;
      color: #828282;
    }
  }
}
