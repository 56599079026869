@import '../../styles/variables.less';

#user-info-component {
  display: flex;
  align-items: center;
  font-size: 1rem;

  .user-dropdown-trigger {
    color: @black-color;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    padding: 10px 0;
    cursor: pointer;

    .ant-avatar {
      border: 1px solid @border-color;
    }
  }
}


.user-dropdown {
  min-width: 266px !important;
  width: 266px !important;
  top: 50px !important;

  padding-top: 8px;

  .user-profile {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 14px 20px 0 20px;

    > div {
      position: relative;
    }

    section {
      h3 {
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
        margin-bottom: 5px;
      }

      span {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .user-menu {
    padding: 24px 16px;
    span {
      font-weight: 700;
    }

    .custom-menu {
      color: @black-color;
      font-size: 1rem;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: @primary-color;
      }
    }

    .btn-logout {
      margin-top: 24px;
      min-width: 234px;
    }

    .nav-link-menu{
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 10px;
      color: @text-dark;
    }
  }

  .nav-link-users-container {
    height: auto !important;
    padding: 6px 10px !important;
  }

  .nav-link-users {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 10px;

    span {
      color: @text-dark;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }

  .ant-divider {
    border-top-color: @border-color;
  }

  .privacy-policies-terms-of-use {
    padding: 14px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        font-size: 0.5rem;
        color: @text-dark;
        transition: color 0.2s;

        &:hover {
          color: @primary-color;
        }

        &:last-child {
          padding-left: 15px;
          margin-bottom: 2px;
          position: relative;
        }

        &:last-child::before {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: @text-dark;
          position: absolute;
          left: 0;
          top: 50%;
          margin-left: 8px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}
