#hidden-paragraph-component {
  display: flex;
  align-items: center;
  word-break: break-word;

  button {
    font-weight: normal;
    margin-right: 10px !important;
  }
}
