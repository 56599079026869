@import '../../../../styles/variables.less';

#datasources-wrapper-component {
  .ant-table-content {
    th.ant-table-cell {
      color: @text-dark;
      font-size: 14.5px;
      font-weight: 500;
    }
  }
}

.multi-select-green {
  .ant-select-selection-item {
    height: 24px;
    margin-right: 20px;
    font-size: 13px;
    background: @primary-color;
    color: @white-color;
    padding-left: 12px;
    padding-right: 9px;
    border-radius: 100px;
    border: none;

    .ant-select-selection-item-content {
      margin-right: 7px;
    }
  }

  .ant-select-selection-item-remove {
    line-height: 1;

    svg {
      color: #fff;
      width: 12px;
      height: 12px;
    }
  }
}

.textarea-resize-none {
  resize: none;
}

.deprecated-switch {
  .ant-switch-checked {
    background: @danger-color;
  }

  .ant-form-item-label, .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.centralized-switch {
  .ant-form-item-label, .ant-form-item-control-input-content {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.timeline-button-wrapper {
  height: 50px;
}

.timeline-button {
  position: absolute;
  z-index: 50;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  border-radius: 8px 0px 0px 0px;
  box-shadow: 0px 4px 20px  rgba(0, 0, 0, 0.2);
  background: @white-color;
  color: @primary-color;
  width: 376px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;

  &:disabled,
  &:disabled:hover {
    background: @secondary-color;
    color: @white-color;
  }
}
