@import '../../../../../../styles/variables.less';

#invoice-create-component {
  .btn-trash {
    color: @text-dark;

    &:hover {
      color: @danger-color;

      &:focus {
        color: @danger-color;
      }
    }

    &:active {
      color: @text-dark;
    }
  }

  .btn-trash.disabled {
    cursor: not-allowed;
  }

  .ant-form-item {
    margin-bottom: 12px !important;
  }

  .modal-title {
    width: fit-content;
    margin-right: 0 !important;
  }
}
