@import "../../../../../../../styles/variables.less";

#card-report-component {
  .header {
    .title {
      margin-top: 10px !important;
    }
    .paragraph {
      margin-top: 3px !important;
    }

    .icon {
      background: @light-primary-color;
      border-radius: 50%;
      padding: 10px;
      margin-right: 15px;
      margin-left: 5px;

      svg {
        color: @primary-color;
        width: 24px;
        height: 24px;
      }
    }
  }
}
