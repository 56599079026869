@import "../../../../../../styles/variables.less";

#mobile-modal-component {
  // background-color: red !important;
  .ant-checkbox-wrapper {
    display: flex;
    // align-items: center;

    margin-bottom: 15px;

    .ant-checkbox {
      margin-top: 20px;
    }

    .checkbox-custom-label {
      display: flex;

      div.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @light-primary-color;
        border-radius: 50%;
        margin-right: 10px;
        padding: 10px;
        width: 48px !important;
        height: 48px !important;
        svg {
          color: @primary-color;
          width: 24px;
          height: 24px;
          margin: 0;
        }
      }

      div.details {
        strong {
          color: @black-color;
        }

        p {
          margin: 0;
          margin-top: 5px;
        }
      }
    }
  }
}
