@import '../../../../../../styles/variables.less';
.react-flow .react-flow__handle {
  width: 30px;
  height: 14px;
  border-radius: 3px;
  background-color: #784be8;
}

.react-flow .react-flow__handle-top {
  top: -10px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -10px;
}

.react-flow .react-flow__node {
  height: auto;
  width: 500px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 2;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
}

.title-rules {
  font-weight: 700;
  font-size: 32px;
  color: #323232;
  line-height: 36px;
}

.subtitle-rules {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323232;
}
.ant-collapse-header-text {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #323232;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0px;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 12px 0px !important;
}
#show-countries-button {
  font-size: 15px;
  color: @primary-color;
  margin-bottom: 30px;
  border: none;
  background: none;
  cursor: pointer;
  strong {
    margin-right: 5px;
  }
}

#wrapper {
  .ant-collapse-header-text {
    font-size: 18px !important;
  }
  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0px !important;
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin: 0px;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
    margin: 10px;
  }
  .ant-tabs-content .ant-tabs-content-top {
    height: 100% !important;
  }
  .ant-tabs-tabpane {
    height: 100% !important;
  }
  .ant-tabs-content {
    height: 100% !important;
  }
  .ant-tabs-tab-active {
    border-bottom: 2px solid #004af7 !important;
  }
  .save-button {
    background-color: #004af7 !important;
    border-color: #004af7 !important;
  }
  .exit-button {
    color: #004af7;
  }

  #executions-tabs-loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    width: 100%;
  }

  #executions-tabs-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto 40px;
    width: 100%;
    height: 100%;

    .tab-item {
      color: @text-dark;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }

    .tab-nav > a {
      padding: 1rem;
      text-align: center;
    }

    .tab-item-icon {
      margin-right: 3px;
    }

    button:not('.ant-switch') {
      background: none;
      border: none;
      outline: none;
    }

    .ant-tabs-tab {
      padding: 0 0 10px;
    }
    .select-onboarding-template {
      width: 25% !important;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 0px !important;
    }
  }
}

.search-input {
  width: 100%;
  height: 30px;
  margin-bottom: 1.5rem !important;
}

.workflow-header {
  height: 60px;
  background-color: #ebebeb;
  color: #323232;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .workflow-header__left {
    position: absolute;
    left: 0.5rem;
  }

  .workflow-header__right {
    position: absolute;
    right: 0.5rem;
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0px;
      margin-right: 0.8rem;
      font-size: 0.8rem;
    }
  }

  .workflow-header__title {
    max-width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    line-height: 18.75px;
  }

  @media only screen and (max-width: 1400px) {
    .workflow-header__title {
      max-width: 20%;
    }
  }

  @media only screen and (max-width: 1240px) {
    .workflow-header__title {
      max-width: 10%;
    }
  }
}
