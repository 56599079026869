@import '../../../../styles/variables.less';

#execution-list-component {
  .ant-card {
    margin-bottom: 14px;

    .ant-card-body {
      padding: 0 !important;
    }
  }

  .document-card {
    &:hover {
      > div {
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2) !important;
        // border-color: #fff !important;
      }
    }
  }

  .editing-data {
    display: none;
  }

  .execution-card-being-edited {
    background-color: @gray-color-3;
  }

  .card-body {
    display: flex;
    flex: 1;
    padding: 20px;
  }

  .card-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1.2;
    color: @text-dark;
    flex: 1;
  }

  .card-report-title {
    line-height: 1.4;
    background: @gray-color-4;
    border-radius: 100px;
    padding: 1px 12px;
    font-size: 12px;
    opacity: 0.9;
  }

  .card-info {
    font-size: 13px;
    color: @text-dark;
    margin-right: 20px;
    display: flex;
    align-items: center;
    line-height: 1;

    &.double-check-reviewed {
      span {
        color: @primary-color;
      }
    }
  }

  .card-footer {
    display: flex;
    position: relative;
    padding: 3px 20px;
    background-color: transparent;
    border-radius: 0 !important;
    border-top: 1px solid @border-color !important;
    justify-content: space-between;
    align-items: center;

    span {
      line-height: 1.2;
    }

    .separator {
      font-style: normal;
      margin: 0 4px;
      line-height: 1;
      color: @text-color-secondary;
    }

    .btn-open {
      border-radius: 50%;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      margin-left: 15px;

      &:hover {
        transform: scale(1.3);
        cursor: pointer;
      }
    }
  }

  .float-tag {
    // position: absolute;
    // top: 15px;
    // right: 15px;
    margin-top: -5px;
    margin-right: -5px;
    margin-left: 10px;
  }

  .loading {
    position: absolute;
    width: 100%;
    z-index: 9999;
    height: 100%;
    background: @body-background;
    opacity: 0.8;
    display: flex;
    justify-content: center;
  }
}
