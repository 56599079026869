@import '../../../../../styles/variables.less';

.page-title {
  margin-bottom: 2rem;
  .title {
    margin-bottom: 0 !important;
  }
}
#price-products-component {
  .product-type {
    width: 100%;

    .card-header {
      align-items: center;

      color: @black-color;
      margin-bottom: 10px;

      display: flex;
      justify-content: space-between;

      svg {
        width: 20px;
        height: 20px;
        color: @primary-color;
      }

      div:not(.gx-text-danger) {
        background: @light-primary-color;
        border-radius: 50%;
        padding: 10px;
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        margin-right: 16px;
      }

      .ant-typography {
        display: inline;
      }
    }

    .card-header-deprecated {
      align-items: center;

      color: @black-color;
      margin-bottom: 10px;

      svg {
        width: 20px;
        height: 20px;
        color: @primary-color;
      }

      div:not(.gx-text-danger) {
        background: @light-primary-color;
        border-radius: 50%;
        padding: 10px;
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        margin-right: 16px;
      }

      .ant-typography {
        display: inline;
      }
    }

    .ant-row {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .custom-data-row {
    margin-right: 15px;
    margin-left: 10px;

    font-size: 20px;

    .ant-typography {
      color: @black-color;
    }

    .deprecated {
      color: @red-color-2;
    }

    .masked-input {
      background: transparent;
      border: 1px solid @gray-color-3;
      border-radius: 5px;
      box-shadow: none !important;
      padding: 2px;
      outline: none;

      input {
        background: transparent;
        height: 35px;
      }

      &:focus {
        border: 1px solid @primary-color;
      }

      .ant-input-prefix {
        margin-right: 10px;
      }
    }
  }

  .custom-card {
    background-color: white;
    padding: 20px 15px 20px 15px;
    border-radius: 10px;
    border: 1px solid @border-color;
    margin-bottom: 50px;
  }
}
