.flow-control {
	.control-buttom {
		width: 1.8rem;
		height: 1.8rem;
		color: #828282;
		padding: 0px !important;

		svg {
			max-width: unset;
			max-height: unset;

			font-size: 1.2rem;
			font-weight: 800px;
		}

		&:hover {
			color: black;
		}
	}

	.zoom-in {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	.zoom-out {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}
