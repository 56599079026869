@import '../../../styles/variables.less';

#executions-component {
  #filter {
    position: sticky;
    width: 310px;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    margin-top: -30px;
    padding-top: 30px;
    overflow-y: auto;
    max-height: calc(100vh - 71px);
    padding-bottom: 100px;
  }

  #list {
    flex: 1;
    padding-left: 40px;
  }
}

.multiselect-option {
  i.contract-icon {
    color: @success-color;
    padding: 1px 2px;
    font-size: 12px;
    border: 1px solid @success-color;
    border-radius: 50%;
  }
}
