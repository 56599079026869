@import '~styles/variables.less';

#iproov-modal-component {
  .ant-checkbox-wrapper {
    display: flex;
    margin-bottom: 15px;

    .ant-checkbox {
      margin-top: 20px;
    }

    .checkbox-custom-label {
      display: flex;
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 25px;

    .btn {
      border: none;
    }
  }

  .ant-input-password-icon {
    color: @primary-color;
  }
}
