@import '../../../styles/variables.less';

.manual-upload-documents-dragger {
  margin-bottom: 15px;
  background: transparent;

  &.ant-upload {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    padding: 0px 12px !important;
    height: 46px !important;
  }

  &.invalid{
    border-color: @danger-color !important;
    background-color: @light-danger-color !important;
  }

  .ant-upload-drag-container {
    display: flex !important;
    align-items: center !important;

    width: 100% !important;

    div:first-of-type {
      display: flex;
      align-items: center;
      flex: 1;

      .icons {
        flex: 0 1 auto;
        align-items: center;
        justify-content: center;
        width: 26px;
        margin-right: 8px;
      }

      svg {
        color: @gray-color-2;

        &.error-icon {
          color: @danger-color;
        }

        &.success-icon {
          color: @primary-color;
        }
      }

      strong {
        font-size: 15px;
        font-weight: 500;
        color: @text-dark;
        white-space: nowrap;
        margin-right: 25px;
      }
    }

    span {
      font-size: 14px;
      color: @text-color-secondary;

      strong {
        font-weight: 500;
        color: @primary-color;
      }
    }
  }

  .manual-upload-documents-file-info {
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }

    button {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 20px;
      height: 20px;

      padding: 0;
      border-radius: 50%;

      background: @gray-color-4 !important;

      cursor: pointer;

      &:hover {
        svg {
          opacity: 0.7;
        }
      }
    }
  }
}

.manual-upload-documents-unsupported-file {
  display: block;
  margin-top: -2px !important;
  margin-bottom: 15px !important;

  color: @danger-color;
  text-align: center;
}

.ant-upload-disabled {
  cursor: initial !important;
  pointer-events: initial !important;
}
