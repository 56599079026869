@import '../../../styles/variables.less';

#unauthorized-page {
    width: 100vw;
    height: 100vh;
  #unauthorized-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      color: @black-color;
      font-size: 32px;
      font-weight: regular;
    }

    .subtitle {
      margin: 0;
      color: @gray-color-1;
    }

    img {
      margin-top: 40px;
      width: 368px;
      height: 304px;
    }
  }
}
