@import '../../../../styles/variables.less';

#datasources-list-wrapper-component {
  .ant-table-content {
    th.ant-table-cell {
      color: @text-dark;
      font-size: 14.5px;
      font-weight: 500;
    }
  }
}
