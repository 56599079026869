.invoices-modal-component .header {
  margin-bottom: 32px;
}

.invoices-modal-component .subtitle {
  color: #828282;
  font-size: 16px;
  font-weight: 400;
}

h1.ant-typography {
  font-size: 32px;
  text-align: left;
  margin-bottom: 5px !important;
}

.date-picker {
  display: flex;
}

.date-picker > span {
  margin-right: 16px;
}

.invoices-table {
  box-shadow: 0px 0px 20px 0px #d1d1d1;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin: 32px 0;

  th.ant-table-cell {
    font-weight: bold;
    border-bottom: none;
  }

  .ant-table-cell {
    padding: 16px;
    margin: auto;
  }

  .checkbox-col {
    width: 0;
    padding: 16px 0 16px 16px !important;
  }

  .fantasy-col {
    width: 450px;
  }
}

.checkbox {
  display: flex;
  align-items: baseline;

  .ant-checkbox-inner {
    border-radius: 20% !important;
  }
}

.date-input {
  width: 100px;
}

.date-error {
  background: #e21b451a;
  border: 1px solid #e21b45;
}

.terms {
  padding: 16px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  width: 100%;
  margin: 16px 0 8px 0;
  background: #ebebeb;
  box-shadow: 0px 0px 20px 0px #d1d1d1;
  color: black;
}

.missing-date {
  display: inline-block;
  width: 100%;
  padding: 8px;
  margin: 8px 0;

  color: rgba(50, 50, 50, 1);
  font-size: 12px;

  background: #e21b451a;
  border: 1px solid #e21b45;
  border-radius: 8px;

  svg {
    margin: 0 4px;
    vertical-align: middle;
    height: 25px;
    width: 25px;
    color: #e21b45;
  }
}

.actions {
  text-align: right;
  margin-top: 32px;
}
