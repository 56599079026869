@import '../../../../styles/variables.less';

#hub-accounts-list-wrapper-component {
  .ant-table-content {
    th.ant-table-cell {
      color: @text-dark;
      font-size: 14.5px;
      font-weight: 500;
    }
  }

  .search-hub {
    .custom-search-input {
      border-bottom: none;
    }

    .ant-input-suffix {
      svg {
        width: 25px;
        height: 25px;
      }
    }

    .ant-input {
      border: 1px solid;
      background: #ffffff;
      border-color: #d1d1d1;
      border-radius: 4px;
      color: #828282;
    }
  }
}
