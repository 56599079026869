@import '../../../../styles/variables.less';

#execution-subheader-component {
  .gx-header-horizontal-top {
    background-color: @white-color;
    color: @black-color;
    padding: 16px 0;

    .main-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;

      .execution-title-column {
        flex: 1;
        min-width: 0;
        flex-basis: 45%;

        .title {
          font-size: 30px;
          margin-bottom: 0 !important;
          font-weight: 900;
          color: @black-color;
        }

        #tag-component {
          min-width: 0;

          .report-tag {
            max-width: 100%;

            .without-reportId {
              display: inline-block;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }

        .subtitle {
          color: @text-color;

          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 100%;
        }

        .client {
          span > span {
            color: @text-color;
          }
          span {
            color: @black-color;
          }

          display: block;

          .text-normal {
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100% - 12px;
            vertical-align: bottom;
          }
        }

        .ant-breadcrumb {
          margin-bottom: 8px;
        }

        .ant-breadcrumb-link {
          color: @text-color;

          a {
            color: @black-color !important;

            &:hover {
              border-bottom: 1px solid @black-color;
            }
          }
        }
        .ant-breadcrumb-separator {
          color: @text-color;
        }
      }

      .status-column {
        text-align: right;

        .ant-switch {
          background: #bfbfbf;
          &.ant-switch-checked {
            background-color: @primary-color;
          }
        }

        .switch-label {
          display: inline;
          margin: 0 20px 0 10px;
          font-weight: bold;
          color: @white-color;
        }

        .extra-info-col {
          .extra-info {
            display: inline-flex;
            align-items: center;
            color: @black-color;

            #tag-component {
              .ant-tag {
                svg {
                  margin-left: 4px;
                }

                .status-title {
                  display: inline-block;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 170px;
                }
              }
            }

            & + .extra-info {
              margin-left: 15px;
            }

            &.exec-status {
              .ant-tag {
                background-color: transparent;
                line-height: 1;
                padding: 0;
                padding-left: 5px;

                span {
                  font-size: 14px;
                }
              }
            }

            .ant-dropdown-trigger {
              cursor: pointer;
              transition: filter 0.2s;

              &:hover {
                filter: brightness(0.75);
              }
            }
          }
        }

        .ant-row + .ant-row {
          padding-top: 16px;
        }
      }
    }
  }

  .report-tag {
    background: @light-primary-color;
    color: @primary-color;
    margin-left: 10px;
    margin-top: 2px;

    a {
      color: @primary-color !important;
    }

    span.without-reportId {
      color: @primary-color !important;
    }
  }

  .tag-rg {
    background: @inverse-success-color;
    color: @success-color;
    margin-left: 10px;
    margin-top: 2px;
  }

  .tag-cnh {
    background: @inverse-purple-color;
    color: @purple-color;
    margin-left: 10px;
    margin-top: 2px;
  }

  .tag-crlv {
    background: @inverse-orange-color;
    color: @orange-color;
    margin-left: 10px;
    margin-top: 2px;
  }

  .tag-ctps {
    background: @gray-color-3;
    color: @text-dark;
    margin-left: 10px;
    margin-top: 2px;
  }

  .tag-rne {
    background: @inverse-pink-color;
    color: @pink-color;
    margin-left: 10px;
    margin-top: 2px;
  }

  .tag-rnm {
    background: @inverse-pink-color;
    color: @pink-color;
    margin-left: 10px;
    margin-top: 2px;
  }

  .tag-passport {
    background: @inverse-warning-color;
    color: @warning-color;
    margin-left: 10px;
    margin-top: 2px;
  }

  .tag-outros {
    background: @inverse-warning-color;
    color: @warning-color;
    margin-left: 10px;
    margin-top: 2px;
  }

  .tags-box {
    display: inline-flex;
    align-items: center;
    border-right: 1px solid @border-color !important;
    margin-right: 15px;
    padding-right: 15px;
  }

  .btn-aws {
    margin-right: 15px;

    .ant-select-selector {
      background-color: @black-color !important;
      border-radius: 100px;
      width: fit-content;
    }
    .ant-select-arrow {
      color: white;
    }
    .ant-select-selection-item {
      padding: 0 10px !important;
    }
  }

  .show-arrow {
    .ant-select-selector {
      width: 90px;
    }
  }

  .btn-menu {
    background-color: transparent !important;
    border-color: transparent;
    color: @black-color !important;
    padding: 0px;
  }

  .select-status,
  .select-status:disabled {
    vertical-align: bottom;
    width: 260px;

    .ant-select-selector {
      border-radius: 100px;
      font-size: 18px;
      font-weight: 700;
      height: 40px;
    }

    .ant-select-selection-item,
    .ant-select-arrow,
    .ant-select-selector {
      color: white;
    }

    svg:not(#arrow) {
      background: white;
      height: 24px;
      width: 24px;
      padding: 4px;
      border-radius: 100%;
    }

    &.processando {
      .ant-select-selector {
        background-color: #763ad7;
      }
      svg:not(#arrow) {
        color: #763ad7;
      }
    }
    &.aprovado {
      .ant-select-selector {
        background-color: #0baa43;
        svg:not(#arrow) {
          color: #0baa43;
        }
      }
    }
    &.reprovado, &.erro {
      .ant-select-selector {
        background-color: #e21b45;
        svg:not(#arrow) {
          color: #e21b45;
        }
      }
    }
    &.pendente {
      .ant-select-selector {
        background-color: #f16e00;
      }
      svg:not(#arrow) {
        color: #f16e00;
      }
    }
    &.fraude {
      .ant-select-selector {
        background-color: #a31332;
      }
      svg:not(#arrow) {
        color: #a31332;
      }
    }
    &.pendente-ocr {
      .ant-select-selector {
        background-color: #c431ac;
      }
      svg:not(#arrow) {
        color: #c431ac;
      }
    }
  }

  .select-status:hover {
    background: white !important;
  }

  .reprocess {
    border-width: 2px;
    display: inline-flex;
    align-items: center;
    height: 40px;
    .icon {
      width: 25px;
      height: 25px;
      margin-right: 0.5rem;
    }
  }

  .report-suspect {
    height: 40px;
    margin-left: 15px;
  }

  .copy-id-button {
    padding: 0;
    font-size: 1rem;
    font-weight: 700;
    color: #004af7;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 0.2rem;
      vertical-align: middle;
    }
  }
}

.status-option {
  font-size: 1rem;
  font-weight: 700;
  border-radius: 4px;
  svg {
    width: 22px;
    height: 22px;
    padding: 3px;
    border-radius: 100px;
  }
}

.pendente {
  color: #f16e00;
  svg {
    background-color: #f16e0029;
  }
}

.pendente:hover {
  background: #f16e0029;
  color: #f16e00;
}

.aprovado {
  color: #0baa43;
  svg {
    background-color: #2d994b29;
  }
}

.aprovado:hover {
  background: #2d994b29;
  color: #0baa43;
}

.reprovado {
  color: #e21b45;
  svg {
    background-color: #e21b4529;
  }
}

.reprovado:hover {
  background: #e21b4529;
  color: #e21b45;
}

.cancel-button {
  color: #828282;
}

.cancel-button-red-modal {
  color: #828282;
  border: 1px solid #fff;
  border-radius: 16px;
}

.cancel-button-red-modal:hover {
  color: #828282;
  border: 1px solid #fff;
  border-radius: 16px;
}

.modal-icon {
  width: 60px;
  height: 60px;
}

.success {
  color: #004af7;
}

.error {
  color: #e21b45;
}

#sharedFacesetExecutionModalContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  gap: 0 16px;
}

.aws-select-dropdown {
  width: 200px !important;
}
