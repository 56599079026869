@import '../../../../../../styles/variables.less';

#subheader-datasources-component {
  background-color: @white-color;
  .gx-header-horizontal-top {
    padding: 16px 0;
  }

  .subheader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .subheader-info {
    flex: 1;
    padding-right: 15px;

    transition: all 0.2s ease-in-out;
  }

  .subheader-name {
    color: @black-color;
    font-weight: 900;
    font-size: 30px;
    margin: 0 0 2px;

    width: fit-content;
    word-break: break-word;
  }
}
