.icon {
  margin-right: 4px;
}

.reproved,
.approved,
.processing,
.pending,
.fraud,
.pending-ocr {
  padding-left: 0;
}

.reproved {
  color: #521717;
  background: #e21b4529;

  .icon {
    color: #e21b45;
  }
}

.approved {
  color: #17521b;
  background: #2d994b29;

  .icon {
    color: #0baa43;
  }
}

.processing {
  color: #391d66;
  background: #763ad729;

  .icon {
    color: #763ad7;
  }
}

.pending {
  color: #664014;
  background: #f16e0029;

  .icon {
    color: #f16e00;
  }
}

.pending-ocr {
  color: #9b2788;
  background: #b03bbd29;

  .icon {
    color: #c431ac;
  }
}

.fraud {
  color: #521717;
  background: #a3133229;
  .icon {
    color: #a31332;
    height: 20px;
    width: 20px;
  }
}
