@import '../../../styles/variables.less';

#executions-component {
  #filter {
    position: sticky;
    width: 291px;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    margin-top: -30px;
    padding-top: 30px;
    overflow-y: auto;
    max-height: calc(100vh - 71px);
    padding-bottom: 100px;
  }

  #list {
    flex: 1;
    padding-left: 40px;
  }
}
