@import '../../../../../../styles/variables.less';
.custom-padding-top {
  padding-top: 40px;
}

.ant-form-vertical .ant-form-item-label > label {
  color: @text-dark !important;
}

.container-form-item-switch {
  display: flex;
  align-items: center;
}
