#page-title-component {
  &.align-center {
    text-align: center;
  }

  &.align-left {
    text-align: left;
  }

  &.align-right {
    text-align: right;
  }
}
