@import "../../../../../../../styles/variables.less";

#timeline-content-component {
  background: @gray-color-4;
  border: 1px solid @gray-color-3;
  border-radius: 8px;
  padding: 15px;
  margin-left: 15px;

  transition: 0.3s all;


  h2 {
    color: @black-color;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;

    & + p {
      margin-top: 6px;
    }
  }

  p {
    color: #909090;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  small {
    color: #909090;
    font-size: 12px;
    line-height: 14px;
  }

  span.open-modal {
    .ant-btn.ant-btn-link {
      padding: 0;
    }
  }

  span, :not("open-modal") {
    display: block;
    margin-top: 4px;
    color: #909090;
    font-size: 12px;
  }

  &.blank {
    align-items: initial;
    padding-bottom: 4px;
    padding-top: 1px;
    border: 0;
    background: @white-color;
  }
}
