@import "../../../styles/variables.less";

#squared-button-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: @white-color;
  height: 170px;
  width: 170px;
  font-size: 15px;
  line-height: normal;
  font-weight: 400;

  border-radius: 8px;
  border: 1px solid @gray-color-3;

  transition: 0.5s all;

  white-space: pre-line !important;
  word-wrap: break-word;


  div {
    transition: 0.5s all;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 70px;
    width: 70px;
    border-radius: 50%;
    font-size: 35px;
    margin-bottom: 10px;

    background: @light-primary-color;
    color: @primary-color
  }

  .ant-btn-loading-icon {
    position: absolute;
    font-size: 35px;
    color: @info-color;
  }

  &:hover {
    border: 2px solid @primary-color;
    background: @light-primary-color;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    color: @text-dark;

    div {
      background: @primary-color;
      color: @white-color;
    }
  }

  &:focus {
    color: @text-color;
  }

  &.danger {
    svg {
      color: @danger-color
    }

    &:hover {
      border: 2px solid @danger-color;
      background: @light-danger-color;

      svg {
        color: @white-color;
      }
    }
  }
}
