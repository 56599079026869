@import '../../styles/variables.less';

#zoomed-image-component,
#pan-zoomed-image-component {
  overflow: auto;
  border-radius: 10px;
  border: 1px solid @border-color;
  background-color: @gray-color-4;
  position: relative;

  &:hover {
    .zoomed-controls {
      display: block;
    }
  }

  .zoomed-controls {
    position: relative;
    display: none;

    button {
      width: 40px !important;
      height: 40px !important;
      padding: 0 !important;
      margin: 10px !important;
    }
  }

  .zoomed-image-label {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    font-size: 12px;
    font-weight: 300;
    color: @white-color;
    padding: 4px 12px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 9;
  }

  .error {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;

    > div {
      flex: 1;
    }
  }

  .left-bottom,
  .right-bottom {
    background-color: @white-color;
    svg {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    .ant-spin {
      margin-right: 0;
      margin-bottom: 0;
    }

    .ant-spin-text {
      color: @text-dark;
      opacity: 0.5;
      font-weight: 300;
    }
  }
}
