@import "../../../../../../../styles/variables.less";

#card-mobile-component {
  .cells {
    display: flex;
    flex-direction: column;

    .ant-typography {
      word-break: break-all;
      margin: 0;
    }
  }
}
