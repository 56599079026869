@import "../../../../../../../styles/variables.less";

#timeline-icon-component {
  display: flex;
  place-items: center;
  place-content: center;
  flex-direction: column;

  background: @primary-color;
  border-radius: 50%;
  height: 30px;
  width: 30px;

  color: @white-color;
  font-size: 18px;

  &.date {
    background: @white-color;

    h3 {
      line-height: 14px;
    }

    h3,
    h6 {
      padding: 0;
      margin: 0;
    }
  }
  &.reprove-reason {
    background: @danger-color;
  }
}
