@import '../../../../../../../../styles/variables.less';
.container-title {
  background: rgba(0, 74, 247, 0.2);
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-title-not-completed {
  background: #f9f9f9;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-rules-label {
  color: @text-dark !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 15px;
}
.box-add-rule {
  background: #e0e0e0;
  border: 2px dashed #bdbdbd;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #323232;
  padding: 20px;
  margin: 15px;
}
.decision-node {
  /* Primary */
  background: #004af7;
  padding: 3px;
  color: white;
  /* Primary */
  border: 1px solid #004af7;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
}
.service-box {
  background: #f9f9f9;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #828282;
}
.subtitle-rule {
  font-weight: 400;
  color: #828282;
  letter-spacing: 0.5px;
  font-size: 13px;
  line-height: 2;
}
.tag-service {
  border-radius: 4px !important;
  background-color: #e0e0e0 !important;
  color: #323232 !important;
}
.button-approved {
  background-color: #39c560 !important;
  border-color: #39c560 !important;
}
.button-approved:hover {
  background-color: #39c560 !important;
  border-color: #39c560 !important;
}
.button-reproved {
  background-color: #e21b45 !important;
  border-color: #e21b45 !important;
}
.button-reproved:hover {
  background-color: #e21b45 !important;
  border-color: #e21b45 !important;
}
