#alert-error-execution-load {
  width: 100%;
  margin: 30px 0 0 0;
}

.link-button {
  font-weight: 700 !important;
  color: #004af7;
  font-size: 1rem;
}

.button-icon {
  width: 25px !important;
  height: 25px !important;
  margin-right: 0.2rem !important;
  vertical-align: middle !important;
}

.saved-filters-select {
  .ant-select-focused {
    box-shadow: none !important;
  }
  .ant-select-selection-item {
    background: none !important;
    color: #323232 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    padding-left: 0 !important;
  }
}
.option-name {
  color: #323232 !important;
}

.saved-filter-options {
  .ant-select-item-option-content {
    padding: 0 !important;
  }
  .filter-option {
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
  }
  .filter-btn {
    padding: 0 !important;
  }
}

.saved-filters-title {
  font-size: 12px;
}
