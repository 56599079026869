@import '../../styles/variables.less';

#markdown-editor-component {
  min-height: 400px;

  .markdown-input {
    textarea {
      resize: none;
      height: 100%;
      padding: 12px 16px;
    }
  }

  .markdown-preview {
    background-color: @gray-color-4;

    .result {
      padding: 12px 6px;

      pre {
        background-color: @gray-color-3;
        padding: 8px 10px;
      }
    }
  }
}
