@import '../../styles/variables.less';

.brl-tag,
.usd-tag,
.eur-tag,
.gbp-tag {
  height: fit-content;
  font-size: 14px;
  font-weight: 400;
  margin-left: 1rem;

  .currency-sign {
    width: 25px;
    height: 20px;
  }
}

.brl-tag {
  color: #17521b;
  background: rgba(#2d994b29, 0.1);

  .currency-sign {
    color: #2d994b;
  }
}

.eur-tag {
  color: #664014;
  background: rgba(#f16e0029, 0.1);

  .currency-sign {
    color: #f16e00;
  }
}

.usd-tag {
  color: #521717;
  background: rgba(#e21b4529, 0.1);

  .currency-sign {
    color: #e21b45;
  }
}

.gbp-tag {
  color: #391d66;
  background: @inverse-purple-color;

  .currency-sign {
    color: @purple-color;
  }
}
