@import '../../../../styles/variables.less';

#customer-list-component {
  .new-invoice {
    svg {
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  .search-client {
    .custom-search-input {
      border-bottom: none;
    }

    .ant-input-suffix {
      svg {
        width: 25px;
        height: 25px;
      }
    }

    .ant-input {
      border: 1px solid;
      background: #ffffff;
      border-color: #d1d1d1;
      border-radius: 4px;
      color: #828282;
    }
  }

  .ant-table-column-sorter {
    display: none;
  }

  .ant-table-column-sorters {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ant-select-selector {
    border-radius: 4px;
    border-color: #d1d1d1;
    height: 35px;
  }

  .ant-select-arrow {
    color: #323232;
  }

  .column-title {
    color: #323232;
    font-weight: 700;
    font-size: 14px;
  }

  svg.exclusive-queue-icon {
    display: flex;
    justify-content: center;
  }

  .checkbox,
  .select-all {
    margin-right: 10px;

    .ant-checkbox-inner {
      border-radius: 20% !important;
    }
  }

  .checkbox {
    align-items: center;
  }

  .select-all {
    color: #004af7;
    font-weight: 700;
    font-size: 12px;
  }
}
