@import '~styles/variables.less';

#card-iproov-component {
  box-shadow: 0px 0px 20px 0px #00000029;

  .header-card {
    border-bottom: 1px solid @border-color;
  }

  .footer-card {
    border-top: 1px solid @border-color;

    .production {
      color: #00257A;
      background-color: #D6E2FE;
    }

    .test {
      color: #391D66;
      background-color: #E9DFF8;
    }
  }

  .cells-left {
    display: flex;
    padding: 20px;
    flex-direction: column;
  }

  .cells {
    display: flex;
    padding: 20px;
    flex-direction: column;
    border-left: 1px solid @border-color;

    .ant-typography {
      word-break: break-all;
      margin: 0;
    }
  }
}
