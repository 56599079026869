@import "../../styles/variables.less";

#tag-component {
  .tag-outlined {
    font-weight: 500;
    line-height: 22px;
  }


  .ant-tag{
    svg {
      margin-left: 6px;
    }
  }

  .ant-tag-error {
    color: @danger-color;
    background-color: @inverse-danger-color;

    &.tag-outlined {
      background-color: transparent;
      border: 1px solid lighten(@danger-color, 10%);
    }
  }

  .ant-tag-success {
    color: @success-color;
    background-color: @inverse-success-color;

    &.tag-outlined {
      background-color: transparent;
      border: 1px solid lighten(@success-color, 10%);
    }
  }

  .ant-tag-warning {
    color: @warning-color;
    background-color: @inverse-warning-color;

    &.tag-outlined {
      background-color: transparent;
      border: 1px solid lighten(@warning-color, 10%);
    }
  }

  .ant-tag-processing {
    color: @info-color;
    background-color: @inverse-info-color;

    &.tag-outlined {
      background-color: transparent;
      border: 1px solid lighten(@info-color, 10%);
    }
  }
}
