@import '../../styles/variables.less';

#header-component {
  &.gx-header-horizontal {
    &::before {
      display: none !important;
    }
  }

  &.gx-inside-header-horizontal {
    .ant-menu-item {
      padding: 0 0;
      margin: 0 7px;
    }
  }

  .gx-logo {
    margin-right: 45px;
  }

  .nav-link {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.6px;
    opacity: 0.8;
    color: @black-color;

    &.active {
      opacity: 1;
      border-width: 2px;
    }

    &:hover {
      opacity: 1;
    }
  }

  header {
    border-bottom: 1px solid @border-color;
    max-height: 200px;


    .logo-caf {
      width: 167px;
      height: 42px;
    }
  }
}
