@import '../../../../styles/variables.less';
#customer-subheader-component {
  background-color: @white-color;
  .gx-header-horizontal-top {
    padding: 16px 0;
  }

  .title {
    font-size: 30px;
    margin-bottom: 0;
    font-weight: 900;
    color: @text-dark;

    > strong {
      color: @text-color;

      button {
        margin-left: 5px;
        background: transparent;
        border: 0;
        cursor: pointer;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  .ant-breadcrumb {
    margin-bottom: 8px;
  }

  .ant-breadcrumb-link {
    color: @text-color;

    a {
      color: @text-dark !important;

      &:hover {
        border-bottom: 1px solid @text-dark;
      }
    }
  }
  .ant-breadcrumb-separator {
    color: @text-color;
  }

  .delete-button {
    display: flex;
    align-items: center;

    .trash {
      margin: 0.5rem;
      height: 20px;
      width: 18px;
    }
  }
}
