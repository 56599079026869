@import '../../../../styles/variables.less';

#socket-banner {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @primary-color;
  color: @white-color;
  z-index: 999;

  &.hideBanner {
    display: none;
  }
}

#alert-error-execution-load {
  width: 100%;
  margin: 50px auto;
}

#execution-wrapper-component {
  .table-bold {
    font-weight: 500;
  }

  .table {
    .header {
      font-weight: bold;
      color: @text-dark;
    }

    p.rule {
      font-weight: bold;
      color: @text-dark;
      margin: 0;
    }

    .ant-row {
      margin: 0;

      & + .ant-row {
        border-top: 1px solid @border-color;
      }

      .ant-col {
        padding: 8px 20px;
        display: flex;
        align-items: center;
      }

      .ant-col-6.border-left {
        border-left: 1px solid @border-color;
      }
    }
  }

  .document-col {
    text-align: center;
    height: 100%;
  }

  .ant-tabs {
    &.ant-tabs-top > .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 8px 0;
        margin: 0 15px 0 0;
      }

      &::before {
        border-bottom: none;
      }
    }
    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        color: @text-dark;
      }
      &:hover {
        color: lighten(@text-dark, 10%);
      }
    }
  }

  .table-overflow {
    border: 1px solid @border-color;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }

    table {
      margin-bottom: 0;
      width: 100%;
      color: lighten(@text-dark, 7%);
      border-collapse: collapse;

      td {
        font-weight: 400;
        width: auto;
        border-left: 1px solid @border-color;
        border-top: 1px solid @border-color;
        line-height: 1.5;
        font-size: 14px;
        padding: 0.55rem 0.75rem;
        vertical-align: top;
        text-align: left;

        &:first-child {
          white-space: nowrap;
          border-left: none;
          width: 1px;
          font-weight: 500;
          background-color: @body-background;
        }
      }

      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }

  #processed-infos-component {
    .formatted-switch-col {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .execution-json {
      color: @text-dark;
      font-size: 0.9em;
    }
  }

  #process-information-component {
    #custom-process-tag {
      margin-right: 5px;
      border-radius: 4px !important;

      font-weight: 500;
      font-size: 14px;
      line-height: 26px;

      cursor: inherit;

      &.default {
        color: @text-color;
        background: @gray-color-4;
      }

      &.danger {
        color: @danger-color;
        background: @inverse-danger-color;
      }

      &.warning {
        color: @warning-color;
        background: @inverse-warning-color;
      }

      &.manual {
        color: @purple-color;
        background: @inverse-purple-color;
      }

      & + & {
        margin-left: 10px;
      }
    }

    #process-information-list {
      #process-information-item {
        .process-information-data {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          strong,
          .process-main-subject {
            color: @text-dark;
            margin-left: 6px;
          }
        }

        .process-information-data-footer {
          display: flex;
          justify-content: flex-start;
          padding-top: 6px;
          align-items: center;
          width: 100%;
        }
      }

      #process-information-item:not(:last-of-type) {
        margin-bottom: 12px;
        border-bottom: 1px solid @border-color;
      }

      #process-information-item:not(:first-of-type) {
        .process-information-data {
          margin-top: 12px;
        }
      }
    }
  }

  #execution-data-confirmation-component,
  #license-confirmation-component,
  #ocr-details-component,
  #parameters-component {
    .ant-card {
      .ant-card-body {
        form {
          .ant-form-item-label {
            justify-content: start;
            padding: 0;
            font-weight: bold;
          }

          .ant-input {
            &[disabled] {
              color: @text-color;
              border-bottom-style: dashed;
            }
            &:not([disabled]) {
              padding-left: 0;
            }
          }

          text-align: left;
        }
      }
    }
  }

  #ocr-details-component {
    .custom-form-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .ant-typography {
        margin-bottom: 0px !important;
        font-size: 15px;
      }
    }

    #compliance-rules-component {
      height: 100%;
    }

    .ocr-details-extra-components {
      .ant-card {
        margin-bottom: 0px !important;
      }
    }
  }

  #parameters-component {
    .ant-card-body {
      overflow: auto;
      flex: 1;
    }
    #empty-message-component {
      width: 100%;
    }
  }

  #ocr-details-component {
    .ant-card {
      height: 100%;
    }
  }

  #compliance-rules-component {
    .table {
      .table-title {
        font-size: 16px;
        margin-top: 25px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  #sanctions-reprove-component {
    .tabs-sanctions {
      .ant-tabs-content-holder {
        max-height: 315px;
        overflow-y: auto;
      }
    }
  }

  #custom-data-available-component,
  #execution-data-confirmation-component {
    .json-viewer {
      overflow: hidden;
      padding: 0.75rem;
    }
  }

  #shared-faceset-confirmation-component,
  #private-faceset-confirmation-component {
    .ant-carousel {
      #zoomed-image-component {
        border-color: @danger-color;

        .zoomed-image-label {
          border-top-left-radius: 8px;
        }
      }

      .ant-form-item-explain-error {
        margin-bottom: 5px;
      }

      .slick-slider {
        margin-bottom: 0px !important;
      }

      .slick-dots {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        li {
          width: 12px !important;

          button {
            height: 12px !important;
            width: 12px !important;

            background-color: @gray-color-2;
            border-radius: 50%;
            opacity: 0.6;
          }

          &.slick-active {
            button {
              background-color: @primary-color;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .ant-anchor {
    margin-left: 5px;
    position: fixed;
    z-index: 999;

    .ant-anchor-link {
      padding-left: 8px;

      // &.this-component-is-active {
      //   a.ant-anchor-link-title {
      //     font-weight: 700;
      //     font-size: 14px;
      //   }
      // }
    }

    .ant-anchor-link-title {
      margin-left: 3px;

      &.ant-anchor-link-title-active {
        font-weight: 700;
        font-size: 13px;
      }

      color: @red-color-2;
      font-weight: 400;
      transition: transform 0.2s;
      font-size: 13px;

      &:hover {
        transform: translateX(2px);
        font-weight: 600;
      }
    }

    .ant-anchor-ink-ball {
      &.visible {
        display: none;
      }

      // border: 3px solid @gray-color-1;
      // width: 10px;
      // height: 10px;
      display: none;
    }

    .ant-anchor-ink::before {
      background: @gray-color-2;
      opacity: 0.3;
    }

    .ant-anchor-link.is-dynamic-card-fixed {
      // .ant-anchor-link {
      //   &.this-component-is-active {
      //     a.ant-anchor-link-title {
      //       font-weight: 700;
      //       font-size: 14px;
      //     }
      //   }
      // }

      .ant-anchor-link-title {
        color: @text-color;
        font-weight: 400;

        &.ant-anchor-link-title-active {
          font-weight: 700;
        }

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  @media (min-width: 200px) and (max-width: 1350px) {
    .ant-anchor {
      margin-top: 110px;

      .ant-anchor-link-title {
        font-size: 11px;

        &.ant-anchor-link-title-active {
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .ant-anchor {
      margin-top: 80px;
    }
  }

  .timeline-button-wrapper {
    height: 50px;
  }

  .timeline-button {
    position: absolute;
    z-index: 50;
    bottom: 0;
    right: 0;
    margin-bottom: 0;
    border-radius: 8px 0px 0px 0px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    background: @white-color;
    color: @primary-color;
    width: 376px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }
}

.reprocess-modal-component {
  img {
    height: 300px;
    margin: 0 auto;
  }

  .ant-empty {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

#execution-data-confirmation-component {
  .equality-icon {
    font-size: 30px;
    margin-left: 15px;
  }
}

#shared-faceset-confirmation-component,
#private-faceset-confirmation-component,
#rg-issue-date-component {
  .ant-card-body {
    #container {
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      justify-items: center;
      width: 100%;

      .contain-width {
        max-width: 278px;
      }

      #images-container {
        width: 100%;
      }

      #inputs-container {
        max-width: 320px;
        width: 320px;
      }

      #standard-button-component {
        max-width: 278px;
        width: 100%;
      }
    }
  }
}

.field-error {
  label {
    font-weight: 900 !important;
    color: @danger-color !important;
  }
}

.field-important {
  label {
    font-weight: 900 !important;
    color: #000000 !important;
  }
}

.tooltip-error {
  width: 10px;
  height: 10px;
  position: sticky;
  bottom: 35%;
  cursor: pointer !important;
  color: @danger-color !important;
  margin: 0.1rem;
}

.tooltip-important {
  width: 10px;
  height: 10px;
  position: sticky;
  bottom: 35%;
  cursor: pointer !important;
  color: #000000 !important;
  margin: 0.1rem;
}
