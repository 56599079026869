@import "../../../../styles/variables.less";

#customer-list-component {
  i.contract-icon {
    color: @primary-color;
    padding: 1px 2px;
    font-size: 14px;
    border: 2px solid @primary-color;
    border-radius: 50%;
  }

}
