.year-picker {
  color: #323232;
  width: 100px;
  font-size: 14px;
  font-weight: bold;
}

.picker-text {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  margin: 8px auto;
}

.year-picker > .ant-select-arrow {
  color: #323232;
}
