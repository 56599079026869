@import '../../../../../../styles/variables.less';

#customer-update-card-component {
  .title-row {
    margin: 0;
    position: relative;
  }

  .currency {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .title {
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 0 !important;
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      background: @primary-color !important;
      color: @white-color !important;
    }
  }

  .ant-select-selector {
    color: @text-dark !important;
  }

  form.disabled {
    input:disabled,
    textarea:disabled,
    .ant-select-selector {
      background: @body-background;
      color: @text-dark;
      font-weight: 500;

      &::placeholder {
        color: @text-dark;
      }
    }
  }
  .switch-label {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-control-input {
      min-height: 0;
    }
  }

  .edit-button {
    color: #004af7;
    border-color: #004af7;
    border-width: 2px;
  }
  .edit-button:hover {
    color: white;
    border-color: #004af7;
    background-color: #004af7;
    border-width: 2px;
  }

  .editing-highlight {
    border-color: #004af7;

    .ant-select-selector {
      border-color: #004af7;
    }
  }

  .info-tooltip {
    width: 16px;
    height: 16px;
    color: blue;
  }

  .title-with-switch {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .ant-form-item-label {
      label {
        font-size: 20px;
        font-weight: 700;
        color: #323232;
      }

      margin-right: 1rem;
    }
  }

  .ant-checkbox-inner {
    border-radius: 4px;
  }

  .checkboxgroup-column-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow-y: auto;
    gap: 16px 0px;
  }

  .ant-checkbox-group-item {
    margin-right: 0px;
  }

  .checkall-button {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-top: 24px;
  }
  .input-with-button {
    margin-right: 19px;
  }

  .list-item {
    padding: 4px;

    span {
      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  .approve-tag {
    background-color: rgba(#2d994b29, 0.1);
  }

  .reprove-tag {
    background-color: rgba(#e21b4529, 0.1);
  }
}

#enable-test-client {
  .cancel-btn {
    color: #323232 !important;
  }
}

#files-upload-card-component {
  .dragger {
    background: @white-color !important;
    margin-bottom: 20px;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 16px;
  }

  .documents-list-item {
    border: 1px solid @gray-color-3;
    border-radius: 12px;
    padding: 10px;

    transition: 0.3s all;

    img {
      margin-right: 10px;
    }

    & + .documents-list-item {
      margin-top: 10px;
    }

    &.contract {
      background: @info-color;

      svg,
      span {
        color: @white-color;
      }

      span:hover {
        transition: 0.3s color;
        color: @gray-color-4;
      }

      .btn-more-icon:hover {
        transition: 0.3s color;
        background: lighten(@info-color, 8%);
      }
    }

    .ant-col.documents-list-item-content {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;

      aside {
        display: flex;
        flex-direction: row;
        overflow: hidden;
      }

      .ant-spin {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin: 8px 13px;
      }

      .ant-dropdown-trigger:hover {
        background-color: @primary-color;
        filter: brightness(0.75);
      }

      .ant-dropdown-trigger.btn-more-icon {
        margin-left: 10px;
      }

      button.ant-btn.ant-btn-link {
        padding: 0;
        font-weight: 400;
        font-size: 13px;
        height: auto;
        color: @text-color;

        text-align: left;
        white-space: normal !important;
        word-wrap: break-word !important;
        line-height: normal !important;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

#active-sdks-card {
  h3 {
    font-size: 32px;
    font-weight: 900;
    line-height: 38px;
    color: @text-dark;
    margin: 38px 0 28px 0;
  }

  .ant-card {
    margin: 14px 0;

    > main {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 24px;
      height: 24px;
      color: @text-dark;
    }

    h4 {
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      margin-left: 6px;
      margin-bottom: 0;
      color: @text-dark;
    }

    section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      & + section {
        margin-top: 16px;
      }

      p {
        max-width: 275px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin-bottom: 0;
        color: @text-dark;
      }

      div {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 3px;

        &.active {
          background: @green-color-2;
        }

        &.inactive {
          background: @red-color-2;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .ant-card section div {
    margin-top: 5px;
  }
}
