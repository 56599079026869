@import '../../../styles/variables.less';

#zoomed-image-component {
  overscroll-behavior: none;

  .scroll-container {
    height: 100%;
    overflow: auto;
    text-align: center !important;

    img {
      padding: 0 !important;
      max-width: 5000px;
      max-height: 5000px;
      object-fit: contain;
    }

    .ant-btn {
      margin-bottom: 20px;
    }
  }
}
