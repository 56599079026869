@import "../../../../../../../styles/variables.less";

#timeline-content-component {
  background: @gray-color-4;
  border: 1px solid @gray-color-3;
  border-radius: 8px;
  padding: 15px;
  margin-left: 15px;

  transition: 0.3s all;


  h2 {
    color: @black-color;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;

    & + p {
      margin-top: 6px;
    }
  }

  p {
    color: @gray-color-1;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  small {
    color: @gray-color-1;
    font-size: 12px;
    line-height: 14px;
  }

  &.starred {
    background: @info-color;
    h2 {
      color: @white-color;
    }

    p,
    small {
      color: rgba(255, 255, 255, 0.71);
    }

    .star {
      color: @info-color;
      opacity: 1;
      visibility: visible;
    }
  }

  .star {
    position: absolute;
    top: -10px;
    right: -10px;

    display: flex;
    align-items: center;
    place-content: center;

    background-color: @white-color;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    font-size: 18px;

    cursor: pointer;

    transition: opacity 0.3s;
    opacity: 0;
    visibility: hidden;

    transition: color 0.3s;
    &.not-starred:hover {
      svg {
        color: @info-color;
      }
    }

    &.starred:hover {
      svg {
        color: @text-color;
      }
    }


    button {
      background: none;
      border: 0;
      cursor: pointer;
    }
  }

  &:hover .star {
    opacity: 1;
    visibility: visible;
  }

  &.blank {
    align-items: initial;
    padding-bottom: 4px;
    padding-top: 1px;
    border: 0;
    background: @white-color;
  }

  span.open-modal {
    .ant-btn.ant-btn-link {
      padding: 0;
    }
  }

  span.data-starred {
    button {
      color: @primary-color;

      transition: color 0.3s;

      &:hover {
        color: rgba(0, 0, 0, 0.6);
      }

      &:active {
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  span, :not("open-modal") {
    display: block;
    margin-top: 4px;
    color: @gray-color-1;
    font-size: 12px;
  }
}
