.invoices-modal-component .content {
  margin: 32px;
  text-align: center;
}

.invoices-modal-component .text {
  color: #323232;
  font-size: 16px;
  font-weight: 400;
}
