@import '../../styles/variables.less';

#content-container-success-step {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 14px;
  justify-content: center;
  align-items: center;

  .ant-input-affix-wrapper .ant-input-prefix {
    margin-left: 15px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .profile-name {
    color: @text-dark;
    margin-top: 28px;
  }

  .copy-link-container {
    text-align: center;
    display: flex;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .input-grid {
    display: grid;
    grid-template-columns: 149px 1fr;

    .form-phone {
      border-radius: 0px;
      height: 35px;
      border-bottom: 1px solid @border-color;
    }
    .form-mail {
      border-radius: 0px;
      height: 35px;
      border-bottom: 1px solid @border-color;
      margin-left: 26px;
    }
  }
  .modal-action-buttons {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 54px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      width: 166px;
    }
  }
}
