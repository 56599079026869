@import "../../../../../../styles/variables.less";

#timeline-component {
  .ant-drawer-content-wrapper {
    padding: 0;

    .ant-drawer-header {
      border-radius: 0;
     background: @white-color;

      .ant-drawer-title {
        color: @primary-color;
      }

      .ant-drawer-close {
        margin-top: 7px;
        color: @primary-color;
      }
    }

    .ant-drawer-body {
      .search-box {
        padding: 0 15px;
        .ant-input-affix-wrapper {
          align-items: center;
          height: 50px;
        }
      }

      .main-content {
        padding: 20px 20px 0 20px;

        &.annotation-create {
          padding: 0 20px ;
        }

        .every-annotation {
          margin-bottom: 60px;
        }
      }
    }

    .ant-drawer-footer {
      height: 80px;
    }
  }

  transform: none !important;
}

