@import "../../../../../../styles/variables.less";

#report-create-component {
  .checkbox-group {
    width: 100%;

    .card-header {
      display: flex;
      align-items: center;

      color: @black-color;
      margin-bottom: 10px;

      div {
        background: @light-primary-color;
        border-radius: 50%;
        padding: 10px;
        margin-right: 15px;
        margin-left: 5px;
      }

      svg {
        color: @primary-color;
        width: 24px;
        height: 24px;
      }

      .ant-typography {
        display: inline;
        margin: 0;
      }
    }
  }

  .custom-data-row {
    .ant-typography {
      color: @black-color;
    }

    & + .custom-data-row {
      margin-top: 7px;
    }
  }

  .availability {
    div {
      label {
        color: @text-dark;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  .search-for-archived-cases {
    color: @text-color-secondary;
  }

  .custom-card {
    background-color: white;
    padding: 20px 15px 20px 15px;
    border-radius: 10px;
    border: 1px solid @border-color;
    margin-bottom: 50px;
  }
}
