@import '../../../styles/variables.less';

#title-divider-component {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: flex-start;

  .ant-divider-horizontal {
    margin: 13px;
  }
}
