.title{
    font-size: 2rem;
    margin-bottom: 1rem !important;
}

.link-button-delete{
    color: #323232 !important;
}

.text{
    font-size: 1rem;
    color: #323232 !important;
}