.title {
  font-size: 2rem !important;
  margin-bottom: 0 !important;
}

.text {
  font-size: 1rem !important;
}

.tooltip {
  color: #004af7;
}

.link-button {
  font-weight: 700 !important;
  color: #004af7;
  font-size: 1rem;
}

.filter-input {
  margin-top: 1rem;
  width: 330px;
}

.filter-label {
  color: #323232;
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bdbdbd;
}

.error-alert {
  width: fit-content;
}

.main-div {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.actions {
  margin-top: 1rem;
}

