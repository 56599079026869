#instepbox {
	.subtitle-rule {
		font-weight: 400;
		color: #828282;
		letter-spacing: 0.5px;
		font-size: 12px;
		padding-bottom: 10px;
	}
	.in-step-box {
		background: #f9f9f9;
		border: 1px solid #bdbdbd;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25), inset 0px 4px 8px rgba(255, 255, 255, 0.5);
		border-radius: 6px;
		padding: 5px 10px;
		cursor: move;
		width: 100%;
		margin-bottom: 5px;
		margin-top: 5px;
		color: #828282;
	}

	.label-input {
		.ant-input-disabled {
			background: #ffffff !important;
			/* Mid Grey */

			border: 1px solid #e0e0e0 !important;
			border-radius: 4px !important;
			color: #000000 !important;
			font-weight: 400 !important;
		}
	}
	.ant-form-vertical .ant-row {
		margin: 0px !important;
	}
	.ant-form-vertical .ant-form-item-label > label {
		font-size: 12px !important;
	}
	.ant-form-vertical .ant-form-item-label {
		line-height: 1 !important;
	}
	.reproved-input {
		.ant-input-disabled {
			background: #ffffff !important;
			/* Mid Grey */

			border: 1px solid #e0e0e0 !important;
			border-radius: 4px !important;
			color: #e21b45 !important;
			font-weight: 700 !important;
		}
	}
}
.warning-option {
	color: #763ad7 !important;
	font-weight: 700 !important;
}
.pending-option {
	color: #f8b239 !important;
	font-weight: 700 !important;
}
.reproved-option {
	color: #e21b45 !important;
	font-weight: 700 !important;
}
.approved-option {
	color: #39c560 !important;
	font-weight: 700 !important;
}
#indragbox {
	.not-dropped {
		border: 1px solid #bdbdbd;
		background: white;
		padding: 12px 10px;
		color: #828282;
		width: 100%;
		border-radius: 4px;
		margin-bottom: 10px;
	}
	.dropped {
		background: linear-gradient(0deg, rgba(0, 74, 247, 0.13), rgba(0, 74, 247, 0.13)), #ffffff;
		border: 1px solid #004af7;
		padding: 12px 10px;
		color: #828282;
		width: 100%;
		border-radius: 4px;
		margin-bottom: 10px;
	}
}

.select__option_container {
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		height: 0.8rem;
		width: 0.8rem;

		margin: 0px !important;
	}

	span {
		margin-left: 7px;
	}
}
