#mobile-list-component {
  .documentation {
    img {
      margin-left: 10px;
    }
  }
}

.custom-col-padding-right {
  padding-right: 0 !important;
}

.flex-jc-end {
  display: flex;
  justify-content: flex-end;
}
