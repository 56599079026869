body,
button {
  letter-spacing: 0.4px;
}

.gx-main-content-wrapper,
.gx-container {
  min-width: 750px;
}

.ant-layout > .gx-layout-content {
  overflow-x: auto;
}

[class^='caf-'],
[class*=' caf-'] {
  font-size: 16px;
  line-height: 1;
}

[class^='caf-']:before,
[class*=' caf-']:before {
  margin: 0 !important;
}

.ant-switch {
  i[class^='caf-'],
  i[class*=' caf-'] {
    font-size: 15px;
    display: flex;
  }
}

.custom-search-input,
.custom-input {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid @gray-color-3;
  box-shadow: none !important;
  padding-top: 0;
  padding-bottom: 0;

  input {
    background: transparent;
    border-radius: 0;
    height: 35px;
  }

  &:hover {
    border-bottom-color: @gray-color-3;
  }

  &:focus-within {
    border-bottom-color: @primary-color;
  }

  .ant-input-prefix {
    margin-right: 10px;
  }
}

.custom-search-input {
  min-width: 230px;
}

.custom-input {
  padding-top: 5px;
  padding-bottom: 3px;
}

.custom-select-input {
  .ant-select-selector {
    border-radius: 0 !important;
    border-bottom: 1px solid @gray-color-3 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .ant-select-selection-item {
    padding-right: 24px !important;
  }
}

.ant-table-column-sorter {
  display: none;
}

.ant-table-column-sorters {
  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-select-arrow {
  width: 24px;
  height: 24px;
  top: 35%;
  svg {
    width: 24px;
    height: 24px;
  }
}

.uppercase-form {
  .custom-input {
    text-transform: uppercase;
  }
}

.page-title {
  .title {
    font-size: 30px;
    margin-bottom: 0;
    font-weight: 900;
  }
  .subtitle {
    color: @text-color-secondary;
  }
}

.text-dark {
  color: @text-dark !important;
}

.text-danger {
  color: @danger-color;
}

.text-default {
  color: @text-color !important;
}

.text-secondary {
  color: @text-color-secondary !important;
}

.btn-custom {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  color: @white-color !important;
  text-shadow: none;
  border: none;

  &.btn-custom-primary {
    background-color: @primary-color;

    &:hover,
    &:active,
    &:focus {
      background-color: @secondary-color;
      color: @white-color;

      &:disabled {
        background-color: @primary-color;
      }
    }
  }

  &.btn-custom-danger {
    background-color: @danger-color;

    &:hover:not(.disabled),
    &:active:not(.disabled),
    &:focus:not(.disabled) {
      background-color: @danger-secondary-color;

      &:disabled {
        background-color: @danger-color;
      }
    }
  }

  &.btn-custom-warning {
    background-color: #f16e00;
  }

  &.btn-custom-green {
    background-color: @green-color;

    &:hover:not(.disabled),
    &:active:not(.disabled),
    &:focus:not(.disabled) {
      background-color: @green-color;

      &:disabled {
        background-color: @green-color;
      }
    }
  }
}

.btn-custom-secondary {
  font-size: 1rem;
  font-weight: 500;
  color: @primary-color;
  border-width: 2px;
  border-color: @primary-color;
  background-color: transparent;
  text-shadow: none;
  padding: 0 16px !important;

  &:hover,
  &:active,
  &:focus {
    background-color: @primary-color !important;
    color: @white-color !important;
  }
}

.btn-more-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: @light-primary-color;
    svg {
      color: @primary-color;
    }
  }
}

.btn-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-simple {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
}

.no-arrow {
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}

input:read-only {
  cursor: default;

  &:focus {
    box-shadow: none;
  }
}

.fd-row {
  flex-direction: row !important;
}

.no-error {
  .ant-form-item-explain {
    animation: none !important;
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

.caf-modal {
  .ant-modal-body {
    padding: 40px;
    padding-bottom: 30px;
  }

  .modal-header {
    margin-bottom: 30px;
  }

  .modal-title {
    font-size: 32px;
    margin-bottom: 0.3em;
    font-weight: 900;
  }

  .modal-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: @text-color-secondary;
  }

  &.offset-timeline {
    right: 370px;
  }
}

a.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.auth-wrapper {
  .auth-form {
    max-width: 380px;
  }
  .auth-form-content {
    .title {
      font-weight: 900;
      font-size: 32px;
      margin-bottom: 8px;
    }
    .subtitle {
      font-weight: 300;
      font-size: 15px;
      display: block;
      margin-bottom: 15px;
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
  }
  .auth-right-title {
    color: #fff;
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 0.6em;
    line-height: 1.15;
  }
  .auth-right-subtitle {
    color: #fff;
    font-size: 16px;
  }
}

.ant-btn.btn-cancel {
  color: @danger-color;
  border: none;

  &:hover {
    color: @danger-secondary-color;
    font-weight: 700;
    span {
      text-decoration: underline;
    }
  }
}

.ant-select-selection-item {
  .option-subtitle {
    visibility: hidden;
  }
}

.multiselect-filters {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;

  .ant-select-item-option {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-select-item-option-content {
    border-bottom: 1px solid @gray-color-4;
    padding-right: 20px;
  }

  .ant-select-item-option-state {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    > span {
      font-size: 13px;
    }
  }

  .ant-select-item-empty {
    font-size: 13px;
    text-align: center;
    font-style: italic;
    padding: 25px 32px;
    word-break: break-word;
  }

  .multiselect-option {
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    .option-title {
      font-size: 13px;
      font-weight: 700;
      color: @text-dark;
      margin-bottom: 4px;
      line-height: 1.2;
      max-width: 200px;
    }

    .option-subtitle {
      font-size: 11px;
      font-weight: 400;
      font-style: italic;
      color: @text-dark;
      line-height: 1.2;
    }
  }
}

.validation-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid @gray-color-3;
  border-radius: 8px;
  padding: 8px 10px;
  margin: 5px;
  background-color: @white-color;

  > svg {
    color: @black-color;
    margin-right: 5px !important;
    width: 18px;
    height: 18px;
  }

  #tag-component {
    display: inline;
    margin-left: 5px;
  }
}

.react-json-view {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace !important;
  font-size: 0.9em;
  overflow: auto;
}

.border-left {
  border-left: 1px solid @gray-color-3;
}

.border-right {
  border-right: 1px solid @gray-color-3;
}

.custom-card-popover {
  .checkmark {
    position: absolute;
    background-color: @primary-color;
    border: @white-color 5px solid;
    border-radius: 50%;
    padding: 3px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 30px;
      height: 30px;
      color: @white-color;
    }
  }

  .ant-popover-inner-content {
    > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px 16px 12px 16px;
      max-width: 300px;

      .custom-card-paragraph {
        text-align: center;

        .ant-typography-primary {
          font-weight: bold;
          color: @primary-color;
        }
      }
    }
  }
}

#custom-filter-component {
  .ant-collapse-arrow {
    padding: 0px;
    top: unset;
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    padding: 7px 0;
    display: flex;
    align-items: center;
    color: @text-dark;
    font-weight: 300;
    font-size: 13px;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: transparent;

    &::after {
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      background-color: @gray-color-4;
      border-radius: 50%;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: @primary-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-radio {
    top: -0.1em;
  }

  .ant-checkbox {
    top: -0.04em;
  }

  .ant-checkbox-inner {
    width: 19px;
    height: 19px;
    border-radius: 25%;
  }

  .ant-checkbox-inner::after {
    left: 25%;
  }

  .ant-collapse {
    border: none;

    .ant-collapse-header {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding: 12px 16px;
    }

    .ant-collapse-item {
      border: none;

      .ant-collapse-content {
        border: none;
        background-color: transparent;
        overflow: initial;

        .ant-collapse-content-box {
          padding-top: 0;
        }
      }
    }
  }

  .ant-select-selector {
    border: none;
    background: transparent;
    border-bottom: 1px solid @gray-color-3;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 4px;
    padding-bottom: 4px;

    .ant-select-selection-overflow
      > .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
      min-width: 100%;

      .ant-select-selection-search {
        min-width: 100%;
      }
    }
  }

  .ant-select-multiple {
    border-bottom: 1px solid transparent;
    width: 100%;

    &.ant-select-focused {
      border-bottom: 2px solid @primary-color;

      .ant-select-selector {
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 9px 28px 8px rgba(0, 0, 0, 0.05);
        background: #fff;
        border-bottom-color: @primary-color;
        border: none;
      }

      .ant-select-arrow-loading {
        background: #fff;
      }
    }
  }

  .ant-select-selection-item {
    height: 24px;
    margin-right: 20px;
    font-size: 13px;
    background: @primary-color;
    color: #fff;
    padding-left: 12px;
    padding-right: 9px;
    border-radius: 100px;
    border: none;

    .ant-select-selection-item-content {
      margin-right: 7px;
    }
  }

  .ant-select-selection-item-remove {
    line-height: 1;

    svg {
      color: #fff;
      width: 12px;
      height: 12px;
    }
  }

  .ant-select-selection-search {
    flex: 1;
    padding-right: 22px;

    input {
      cursor: text;
    }

    &::after {
      position: absolute;
      right: 0;
      color: #bbb;
      font-size: 17px;
      bottom: 0;
      line-height: 1;
    }
  }

  .ant-select-arrow-loading {
    bottom: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: auto;
    right: 7px;
    background: @body-background;
    color: @text-dark;
  }

  .execution-select {
    width: calc(100% - 16px);
    margin-bottom: 20px;

    .ant-select-selector {
      box-shadow: none !important;
      background: transparent !important;
    }
  }

  .btn-filters {
    button {
      width: 291px;
      font-weight: 500;
      margin-top: 16px;
    }

    button:first-child {
      margin-top: 40px;
    }
  }
}
