@import '../../../styles/variables.less';

#dashboard-component {
  height: 100%;

  .ant-tabs-tab {
   div{
    font-size: 16px;
    color: @black-color;
    font-weight: 700;
    opacity: 0.5;
   }
  }

  .ant-tabs-tab-active {
    div {
      opacity: 1;
    }
  }


  .ant-tabs.ant-tabs-top,
  .ant-tabs-content.ant-tabs-content-top {
    height: 100%;
  }

  .invoices-table .ant-table {
    overflow-x: auto;
  }

  .export-list-btn {
    border: none;
    background: transparent;
    transition: color 0.2s ease-in-out;

    .ant-btn-loading-icon {
      color: @primary-color;
    }
  }
}
