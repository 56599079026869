.invoices-modal-component .header {
  margin-bottom: 32px;
}

.invoices-modal-component .subtitle {
  color: #828282;
  font-size: 16px;
  font-weight: 400;
}

h1.ant-typography {
  font-size: 32px;
  text-align: left;
  margin-bottom: 5px !important;
}

.invoices-table {
  box-shadow: 0px 0px 20px 0px #d1d1d1;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin: 32px 0;

  .ant-table-cell {
    padding: 16px;
    margin: auto;
  }

  .fantasy-col {
    width: 500px;
  }

  .status-col {
    text-align: center;
  }

  .generating,
  .success-generating,
  .error-generating,
  .download-icon {
    vertical-align: middle;
  }

  .generating,
  .success-generating {
    color: #004af7;
    width: 20px;
    height: 20px;
  }

  .error-generating {
    color: #e21b45;
    width: 20px;
    height: 20px;
  }
}

.download-icon {
  width: 20px;
  height: 20px;
}

.download {
  width: 25px;
  height: 25px;
  padding-right: 8px;
}

.actions {
  margin-top: 32px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.hide-header {
  th.ant-table-cell {
    display: none;
  }
}
