@font-face {
  font-family: 'caf-icons';
  src: url('../fonts/caf-icons.eot?93048341');
  src: url('../fonts/caf-icons.eot?93048341#iefix') format('embedded-opentype'),
    url('../fonts/caf-icons.woff2?93048341') format('woff2'),
    url('../fonts/caf-icons.woff?93048341') format('woff'),
    url('../fonts/caf-icons.ttf?93048341') format('truetype'),
    url('../fonts/caf-icons.svg?93048341#caf-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'caf-icons';
    src: url('../fonts/caf-icons.svg?93048341#caf-icons') format('svg');
  }
}
*/

[class^='caf-']:before,
[class*=' caf-']:before {
  font-family: 'caf-icons';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.caf-ic_activity:before {
  content: '\e800';
} /* '' */
.caf-ic_add:before {
  content: '\e801';
} /* '' */
.caf-ic_admin:before {
  content: '\e802';
} /* '' */
.caf-ic_alert-triangle:before {
  content: '\e803';
} /* '' */
.caf-ic_anchor:before {
  content: '\e804';
} /* '' */
.caf-ic_archive:before {
  content: '\e805';
} /* '' */
.caf-ic_arrow_down:before {
  content: '\e806';
} /* '' */
.caf-ic_arrow_left:before {
  content: '\e807';
} /* '' */
.caf-ic_arrow_right:before {
  content: '\e808';
} /* '' */
.caf-ic_arrow_up:before {
  content: '\e809';
} /* '' */
.caf-ic_arrow-down-right:before {
  content: '\e80a';
} /* '' */
.caf-ic_arrow-right:before {
  content: '\e80b';
} /* '' */
.caf-ic_arrow-up-right:before {
  content: '\e80c';
} /* '' */
.caf-ic_attach:before {
  content: '\e80d';
} /* '' */
.caf-ic_awards:before {
  content: '\e80e';
} /* '' */
.caf-ic_backward:before {
  content: '\e80f';
} /* '' */
.caf-ic_bolt:before {
  content: '\e810';
} /* '' */
.caf-ic_calendar:before {
  content: '\e811';
} /* '' */
.caf-ic_check_d:before {
  content: '\e812';
} /* '' */
.caf-ic_checkmark:before {
  content: '\e813';
} /* '' */
.caf-ic_circle_hold:before {
  content: '\e814';
} /* '' */
.caf-ic_circle_false:before {
  content: '\e815';
} /* '' */
.caf-ic_circle_true:before {
  content: '\e816';
} /* '' */
.caf-ic_clock:before {
  content: '\e817';
} /* '' */
.caf-ic_close:before {
  content: '\e818';
} /* '' */
.caf-ic_cloud:before {
  content: '\e819';
} /* '' */
.caf-ic_combateafraude:before {
  content: '\e81a';
} /* '' */
.caf-ic_company:before {
  content: '\e81b';
} /* '' */
.caf-ic_contact_:before {
  content: '\e81c';
} /* '' */
.caf-ic_criminal:before {
  content: '\e81d';
} /* '' */
.caf-ic_documentation:before {
  content: '\e81e';
} /* '' */
.caf-ic_dollar_sign:before {
  content: '\e81f';
} /* '' */
.caf-ic_driver:before {
  content: '\e820';
} /* '' */
.caf-ic_eye:before {
  content: '\e821';
} /* '' */
.caf-ic_face:before {
  content: '\e822';
} /* '' */
.caf-ic_file:before {
  content: '\e823';
} /* '' */
.caf-ic_filter:before {
  content: '\e824';
} /* '' */
.caf-ic_glasses:before {
  content: '\e825';
} /* '' */
.caf-ic_hold:before {
  content: '\e826';
} /* '' */
.caf-ic_home:before {
  content: '\e827';
} /* '' */
.caf-ic_link:before {
  content: '\e828';
} /* '' */
.caf-ic_match:before {
  content: '\e829';
} /* '' */
.caf-ic_maximize:before {
  content: '\e82a';
} /* '' */
.caf-ic_minimize:before {
  content: '\e82b';
} /* '' */
.caf-ic_onboardind_big:before {
  content: '\e82c';
} /* '' */
.caf-ic_processing:before {
  content: '\e82d';
} /* '' */
.caf-ic_rotate-ccw:before {
  content: '\e82e';
} /* '' */
.caf-ic_key:before {
  content: '\e82f';
} /* '' */
.caf-ic_lock_valid:before {
  content: '\e830';
} /* '' */
.caf-ic_more-horizontal:before {
  content: '\e831';
} /* '' */
.caf-ic_sun:before {
  content: '\e832';
} /* '' */
.caf-ic_law:before {
  content: '\e833';
} /* '' */
.caf-ic_search:before {
  content: '\e834';
} /* '' */
.caf-ic_person:before {
  content: '\e835';
} /* '' */
.caf-ic_map_pin:before {
  content: '\e836';
} /* '' */
.caf-ic_notification:before {
  content: '\e837';
} /* '' */
.caf-ic_zoom-out:before {
  content: '\e838';
} /* '' */
.caf-ic-message-circle:before {
  content: '\e839';
} /* '' */
.caf-ic_plus:before {
  content: '\e83a';
} /* '' */
.caf-ic_reorder:before {
  content: '\e83b';
} /* '' */
.caf-ic_mail:before {
  content: '\e83c';
} /* '' */
.caf-ic_processing_line:before {
  content: '\e83d';
} /* '' */
.caf-ic_onboarding:before {
  content: '\e83e';
} /* '' */
.caf-ic_rotate-cw:before {
  content: '\e83f';
} /* '' */
.caf-ic_more-vertical:before {
  content: '\e840';
} /* '' */
.caf-ic_news:before {
  content: '\e841';
} /* '' */
.caf-ic_smartphone:before {
  content: '\e842';
} /* '' */
.caf-ic_trash:before {
  content: '\e843';
} /* '' */
.caf-ic_zoom-in:before {
  content: '\e844';
} /* '' */
.caf-ic_settings:before {
  content: '\e845';
} /* '' */
.caf-user-plus:before {
  content: '\e846';
} /* '' */
.caf-ic_upload:before {
  content: '\e847';
} /* '' */
.caf-ic_user_inactive:before {
  content: '\e848';
} /* '' */
.caf-lock:before {
  content: '\e849';
} /* '' */
.caf-ic_user_active:before {
  content: '\e84a';
} /* '' */
.caf-ic_open_full:before {
  content: '\e84b';
} /* '' */
.caf-ic_hash:before {
  content: '\e84c';
} /* '' */
.caf-ic_star_filled:before {
  content: '\e84d';
} /* '' */
.caf-ic_star_outline:before {
  content: '\e84e';
} /* '' */
.caf-ic_image:before {
  content: '\e84f';
} /* '' */
.caf-ic_download:before {
  content: '\e850';
} /* '' */
.caf-ic_camera_switch:before {
  content: '\e851';
} /* '' */
.caf-ic_crash:before {
  content: '\e852';
} /* '' */
.caf-ic_car_plate:before {
  content: '\e853';
} /* '' */
.caf-ic_forward:before {
  content: '\e854';
} /* '' */
.caf-ic_lawier:before {
  content: '\e855';
} /* '' */
.caf-ic_tag:before {
  content: '\e856';
} /* '' */
.caf-ic_edit:before {
  content: '\e857';
} /* '' */
.caf-ic_git-branch:before {
  content: '\e858';
} /* '' */
.caf-ic_x_close:before {
  content: '\e859';
} /* '' */
.caf-ic-corner-up-right:before {
  content: '\e85a';
} /* '' */
.caf-ic-first:before {
  content: '\e85b';
} /* '' */
.caf-ic_users:before {
  content: '\e85c';
} /* '' */
.caf-ic_c_download:before {
  content: '\e85d';
} /* '' */
.caf-ic_duplicate:before {
  content: '\e85e';
} /* '' */
.caf-ic_export:before {
  content: '\e85f';
} /* '' */
.caf-ic_import:before {
  content: '\e860';
} /* '' */
.caf-ic_car:before {
  content: '\e862';
} /* '' */
.caf-ic_chassi:before {
  content: '\e863';
} /* '' */
.caf-ic_driver_open:before {
  content: '\e864';
} /* '' */
.caf-ic_motor:before {
  content: '\e865';
} /* '' */
.caf-ic_weight:before {
  content: '\e866';
} /* '' */
.caf-ic_edit_2:before {
  content: '\e867';
} /* '' */
.caf-ic_edit_3:before {
  content: '\e868';
} /* '' */
.caf-ic_bus:before {
  content: '\e869';
} /* '' */
