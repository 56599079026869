.product-card-divider{
  margin: 0px 0px 10px 0px !important;
}
.product-card-tooltip-info{
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.product-card-execution-limit-button{
  margin-right: 0px !important;
  padding-right: 0px !important;
}
