@import "../../../../../../styles/variables.less";

#mobile-modal-component {
  // background-color: red !important;
  .ant-checkbox-wrapper {
    display: flex;
    // align-items: center;

    margin-bottom: 15px;

    .ant-checkbox {
      margin-top: 20px;
    }

    .checkbox-custom-label {
      display: flex;

      div.details {
        strong {
          color: @black-color;
        }

        p {
          margin: 0;
          margin-top: 5px;
        }
      }
    }
  }
}
