@import '../../../../../styles/variables.less';

.management-user-list-items {
  .ant-col-24 {
    background-color: @gray-color-4;
    padding: 5px;
    border-radius: 8px;
    margin-top: 15px;

    .list-number {
      display: inline;
      position: static;
      margin: 0px 10px;
      padding: 10px;

      font-weight: bold;
      border-radius: 50% !important;
      background-color: rgba(@text-color, 0.2);

      &.creating {
        color: @info-color;
        background-color: rgba(@info-color, 0.2);
      }

      &.error {
        color: @danger-color;
        background-color: rgba(@danger-color, 0.2);
      }

      &.success {
        color: @primary-color;
        background-color: rgba(@primary-color, 0.2);
      }
    }
  }
}
